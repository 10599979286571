import { Link, graphql, useStaticQuery } from "gatsby";
import React, { useState, useEffect } from "react";

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Hamburger from 'react-hamburgers';

import "./header.scss"
import { isMobile, isTablet, isBrowser, isMobileSafari, isSafari } from "react-device-detect";

const moduleQuery = graphql`
  {
    gcms {
      submenuModules(orderBy: priority_ASC) {
        headerName
        modules {
          moduleName
          moduleType
          slug
        }
      } 
      customerTypes(orderBy: priority_ASC) {
        customerTypeName
        slug
      }
    }
  }
`;

const Header = () => {
  const {
    gcms: { submenuModules, customerTypes }
  } = useStaticQuery(moduleQuery);

  // const modules = submenuModules[0].modules;
  const [showMobile, setMobileOpen] = useState(false);
  const [showModules, setShowModules] = useState(false);
  const [showCustomerTypes, setShowCustomerTypes] = useState(false);
  const [tablet, setTablet] = useState(false);
  const [activePage, setActivePage] = useState("");
  // const [scrolledDown, setScrolledDown] = useState(false);
  const [hovered, setHovered] = useState("");
  const [headerImage, setHeaderImage] = useState("");

  const handleMobileMenu = () => {
    if (!showMobile) {
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.documentElement.style.overflow = 'unset';
    }
    setMobileOpen(!showMobile);
  }

  const cleanup = () => {
    document.documentElement.style.overflow = 'unset';
    if (isMobile || isTablet) {
      setMobileOpen(false);
    }
    setHovered('');
  }

  const onScroll = () => {
    const element = document.querySelector('.header-container');
    if (element != null) {
      if (window.scrollY > 350) {
        element.classList.add('scroll-menu');
      } else {
        element.classList.remove('scroll-menu');
      }
    }
  }

  if (typeof window !== "undefined") {
    window.addEventListener('scroll', onScroll, true);
  }

  useEffect(() => {
    if (window.location.pathname.indexOf('/modules') > -1) {
      setActivePage('modules');
    } else if (window.location.pathname.indexOf('/voor-wie') > -1) {
      setActivePage('voor wie');
    } else if (window.location.pathname.indexOf('/portfolio') > -1) {
      setActivePage('portfolio');
    } else if (window.location.pathname.indexOf('/partners') > -1) {
      setActivePage('partners');
    } else if (window.location.pathname.indexOf('/nieuws') > -1) {
      setActivePage('nieuws');
      // } else if (window.location.pathname.indexOf('/support') > -1) {
      //   setActivePage('support');
    } else if (window.location.pathname.indexOf('/contact') > -1) {
      setActivePage('contact');
    } else if (window.location.pathname.indexOf('/compleet') > -1) {
      setActivePage('compleet');
    }

    if ((isTablet === true && isBrowser === false) || isMobileSafari === true) {
      setTablet(true);
    } else {
      setTablet(false);
    }

    if (isSafari === true) {
      setHeaderImage('https://media.travelspirit.nl/original/sm/TravelSpirit_Logo_White-2.png');
    } else {
      setHeaderImage('https://media.travelspirit.nl/original/smw/TravelSpirit_Logo_White-2.webp');
    }

    return function cleanup() {
      window.removeEventListener('scroll', onScroll, true);
    }
  }, []);

  return (
    <>
      <header className={"header-container container " + (showMobile && "mobile-menu-triggered ")}>
        <div className="inner-container">
          <Link className="header-links" to="/">{headerImage && <img src={headerImage} alt="logo" loading="lazy" />}</Link>
          <ul className="menu">
            {tablet ? <li className={activePage === 'modules' ? `active-page menu-list-item dropdown` : `menu-list-item dropdown`}>
              <button onClick={() => setHovered('hovered')}>
                <span className="header-links">Modules
                  <div className="icon baseline down-icon"><KeyboardArrowDownIcon /></div>
                  <div className="icon baseline up-icon"><KeyboardArrowUpIcon /></div>
                </span>
              </button>
              <div className={`dropdown-content ${hovered}`}>
                <div className="offset-sm-1 col-sm-11 dropdown-inner-container">
                  <ul className="dropdown-module-list">
                    {submenuModules && submenuModules.map((moduleGroup) => {
                      return <span key={moduleGroup.headerName}>
                        <li className="module-title module-item" key={moduleGroup.headerName + '-header'}><Link key={moduleGroup.headerName} to={`/${moduleGroup.headerName.toLowerCase()}-modules`} onClick={() => cleanup()}>{moduleGroup.headerName}</Link></li>
                        {moduleGroup.modules && moduleGroup.modules.map(({ slug, ...module }) => {
                          return <li className="module-item" key={slug}>
                            <Link key={slug} to={`/modules/${slug}`} onClick={() => cleanup()}>
                              {module.moduleName}
                              {module.moduleType && module.moduleType === 'new' && <sup> new</sup>}
                              {module.moduleType && module.moduleType === 'important' && <span className="pulsating-circle"></span>}
                            </Link>
                          </li>
                        })}
                      </span>
                    })}
                  </ul>
                </div>
              </div>
            </li>
              : <li className={activePage === 'modules' ? `active-page menu-list-item dropdown` : `menu-list-item dropdown`}>
                <button onMouseEnter={() => setHovered('hovered')}>
                  <Link className="header-links" to="/">Modules
                    <div className="icon baseline down-icon"><KeyboardArrowDownIcon /></div>
                    <div className="icon baseline up-icon"><KeyboardArrowUpIcon /></div>
                  </Link>
                </button>
                <div className={`dropdown-content ${hovered}`}>
                  <div className="offset-sm-1 col-sm-11 dropdown-inner-container">
                    <ul className="dropdown-module-list">
                      {submenuModules && submenuModules.map((moduleGroup) => {
                        return <span key={moduleGroup.headerName}>
                          <li className="module-title module-item" key={moduleGroup.headerName + '-header'}><Link key={moduleGroup.headerName} to={`/${moduleGroup.headerName.toLowerCase()}-modules`} onClick={() => cleanup()}>{moduleGroup.headerName}</Link></li>
                          {moduleGroup.modules && moduleGroup.modules.map(({ slug, ...module }) => {
                            return <li className="module-item" key={slug}>
                              <Link key={slug} to={`/modules/${slug}`} onClick={() => cleanup()}>
                                {module.moduleName}
                                {module.moduleType && module.moduleType === 'new' && <sup> new</sup>}
                                {module.moduleType && module.moduleType === 'important' && <span className="pulsating-circle"></span>}
                              </Link>
                            </li>
                          })}
                        </span>
                      })}
                    </ul>
                  </div>
                </div>
              </li>
            }
            {/* <li className={activePage === 'voor wie' ? "active-page menu-list-item" : "menu-list-item"}><Link className="header-links" to="/voor-wie">Voor wie</Link></li> */}
            {tablet ? <li className={activePage === 'voor wie' ? `active-page menu-list-item dropdown` : `menu-list-item dropdown`}>
              <button onClick={() => setHovered('hovered')}>
                <span className="header-links">Voor wie
                  <div className="icon baseline down-icon"><KeyboardArrowDownIcon /></div>
                  <div className="icon baseline up-icon"><KeyboardArrowUpIcon /></div>
                </span>
              </button>
              <div className={`dropdown-content dropdown-content-customertypes ${hovered}`}>
                <div className="dropdown-inner-container">
                  <ul className="dropdown-module-list dropdown-customertypes-list">
                    {customerTypes && customerTypes.map((customerType) => {
                      return <span key={customerType.slug}>
                      {customerType.slug !== 'start-pakket' && <li className="module-item" key={customerType.slug + '-header'}><Link key={customerType.slug} to={`/voor-wie#${customerType.slug.toLowerCase()}-container`} onClick={() => cleanup()}>{customerType.customerTypeName}</Link></li>}
                      {customerType.slug === 'start-pakket' && <li className="module-item" key={customerType.slug + '-header'}><Link key={customerType.slug} to={`/${customerType.slug.toLowerCase()}`} onClick={() => cleanup()}>{customerType.customerTypeName}</Link></li>}
                      </span>
                    })}
                  </ul>
                </div>
              </div>
            </li>
              : <li className={activePage === 'voor wie' ? `active-page menu-list-item dropdown` : `menu-list-item dropdown`}>
                <button onMouseEnter={() => setHovered('hovered')}>
                  <Link className="header-links" to="/voor-wie">Voor wie
                    <div className="icon baseline down-icon"><KeyboardArrowDownIcon /></div>
                    <div className="icon baseline up-icon"><KeyboardArrowUpIcon /></div>
                  </Link>
                </button>
                <div className={`dropdown-content dropdown-content-customertypes ${hovered}`}>
                  <div className="dropdown-inner-container">
                    <ul className="dropdown-module-list dropdown-customertypes-list">
                      {customerTypes && customerTypes.map((customerType) => {
                        return <span key={customerType.slug}>
                          {customerType.slug !== 'start-pakket' && <li className="module-item" key={customerType.slug + '-header'}><Link key={customerType.slug} to={`/voor-wie#${customerType.slug.toLowerCase()}-container`} onClick={() => cleanup()}>{customerType.customerTypeName}</Link></li>}
                          {customerType.slug === 'start-pakket' && <li className="module-item" key={customerType.slug + '-header'}><Link key={customerType.slug} to={`/${customerType.slug.toLowerCase()}`} onClick={() => cleanup()}>{customerType.customerTypeName}</Link></li>}
                        </span>
                      })}
                    </ul>
                  </div>
                </div>
              </li>
            }
            <li className={activePage === 'portfolio' ? "active-page menu-list-item" : "menu-list-item"}><Link className="header-links" to="/portfolio">Portfolio</Link></li>
            <li className={activePage === 'partners' ? "active-page menu-list-item" : "menu-list-item"}><Link className="header-links" to="/partners">Partners</Link></li>
            <li className={activePage === 'nieuws' ? "active-page menu-list-item" : "menu-list-item"}><Link className="header-links" to="/nieuws">Nieuws</Link></li>
            {/* <li className={activePage === 'support' ? "active-page menu-list-item" : "menu-list-item"}><Link className="header-links" to="/support">Support</Link></li> */}
            <li className={activePage === 'contact' ? "active-page menu-list-item" : "menu-list-item"}><Link className="header-links" to="/contact">Contact</Link></li>
            <li className={activePage === 'compleet' ? `menu-list-item-button hide-button` : `menu-list-item-button`}>
              <span className="header-links orange-button">
                <Link id="header-demo-button" className="header-links" to="/demo">Demo inplannen</Link>
                {/* <PopupText
                  color="#f8efef"
                  text="Vraag demo aan"
                  textColor="#484a4d"
                  primaryColor="#a6d3ed"
                  backgroundColor="#f4eaea"
                  url="https://calendly.com/travelspirit/demo"
                /> */}
              </span>
            </li>
          </ul>
          <div className="mobile-menu">
            <Hamburger
              active={showMobile}
              type="slider"
              onClick={() => handleMobileMenu()}
            ></Hamburger>
          </div>
        </div>
      </header>
      {showMobile && <div className="mobile-menu-items">
        <ul>
          <li className="menu-list-item dropdown">
            <button className={showModules ? "modules-triggered" : ""} onClick={() => setShowModules(!showModules)}>Modules
              <div className="icon baseline down-icon">
                {showModules ? <KeyboardArrowDownIcon /> : <NavigateNextIcon />}
              </div>
            </button>
            {showModules && <ul>
              {/* <li className="module-item" key='modules-header'><Link key='modules' to={`/modules`} onClick={() => cleanup()}>Naar Modules</Link></li> */}
              {submenuModules && submenuModules.map((moduleGroup) => {
                return <span key={moduleGroup.headerName}>
                  <li className="module-title module-item" key={moduleGroup.headerName + '-header'}><Link key={moduleGroup.headerName} to={`/${moduleGroup.headerName.toLowerCase()}-modules`} onClick={() => cleanup()}>{moduleGroup.headerName}</Link></li>
                  {moduleGroup.modules && moduleGroup.modules.map(({ slug, ...module }) => {
                    return <li className="module-item" key={slug}>
                      <Link key={slug} to={`/modules/${slug}`} onClick={() => cleanup()}>
                        {module.moduleName}
                        {module.moduleType && module.moduleType === 'new' && <sup> new</sup>}
                        {module.moduleType && module.moduleType === 'important' && <span className="pulsating-circle"></span>}
                      </Link>
                    </li>
                  })}
                </span>
              })}
            </ul>}
          </li>
          {/* <li>
            <Link className="header-links" onClick={() => cleanup()} to="/voor-wie">Voor wie</Link>
          </li> */}
          <li className="menu-list-item dropdown">
            <button className={showCustomerTypes ? "modules-triggered" : ""} onClick={() => setShowCustomerTypes(!showCustomerTypes)}>Voor wie
              <div className="icon baseline down-icon">
                {showModules ? <KeyboardArrowDownIcon /> : <NavigateNextIcon />}
              </div>
            </button>
            {showCustomerTypes && <ul>
              <li className="module-item custom-item" key='voor-wie-header'><Link key='voor-wie-pagina' to={`/voor-wie`} onClick={() => cleanup()}>Naar Voor Wie</Link></li>
              {customerTypes && customerTypes.map((customerType) => {
                return <span key={customerType.slug}>
                  {customerType.slug !== 'start-pakket' && <li className="module-item" key={customerType.slug + '-header'}><Link key={customerType.slug} to={`/voor-wie#${customerType.slug.toLowerCase()}-container`} onClick={() => cleanup()}>{customerType.customerTypeName}</Link></li>}
                  {customerType.slug === 'start-pakket' && <li className="module-item" key={customerType.slug + '-header'}><Link key={customerType.slug} to={`/${customerType.slug.toLowerCase()}`} onClick={() => cleanup()}>{customerType.customerTypeName}</Link></li>}
                </span>
              })}
            </ul>}
          </li>
          <li className="menu-list-item">
            <Link className="header-links" onClick={() => cleanup()} to="/portfolio">Portfolio</Link>
          </li>
          <li>
            <Link className="header-links" onClick={() => cleanup()} to="/partners">Partners</Link>
          </li>
          <li>
            <Link className="header-links" onClick={() => cleanup()} to="/nieuws">Nieuws</Link>
          </li>
          {/* <li className="menu-list-item">
            <Link className="header-links" onClick={() => cleanup()} to="/support">Support</Link>
          </li> */}
          <li>
            <Link className="header-links" onClick={() => cleanup()} to="/contact">Contact</Link>
          </li>
        </ul>
        <div className="row">
          <div className={activePage === 'compleet' ? `col-9 demo-container hide-button` : `col-9 demo-container`}>
            <span className="header-links orange-button">
              <Link id="header-demo-button" className={activePage === 'compleet' ? `header-links hide-button` : `header-links`} to="/demo">Demo inplannen</Link>
              {/* <PopupText
                color="#f8efef"
                text="Demo"
                textColor="#484a4d"
                primaryColor="#a6d3ed"
                backgroundColor="#f4eaea"
                url="https://calendly.com/travelspirit/demo"
              /> */}
            </span>
          </div>
        </div>
        <ul>
          <li>
            <Link className="header-links sub-header-links" onClick={() => cleanup()} to="/ervaringen">Ervaringen</Link>
          </li>
          <li>
            <Link className="header-links sub-header-links" onClick={() => cleanup()} to="/over-ons">Over ons</Link>
          </li>
          <li>
            <Link className="header-links sub-header-links" onClick={() => cleanup()} to="/in-de-media">In de media</Link>
          </li>
          {/* <li>
            <Link className="header-links sub-header-links" onClick={() => cleanup()} to="/vlog">Vlog</Link>
          </li> */}
          <li>
            <Link className="header-links sub-header-links" onClick={() => cleanup()} to="/conculegas">Conculega's</Link>
          </li>
        </ul>
      </div>}
    </>
  );
}

export default Header
